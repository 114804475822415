.purple-header {
  background-color: #6b489c !important;
  padding: 1.2rem;
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  border-radius: 100em;
  min-height: 95px !important;
  margin-bottom: 7px;
  font-weight: 600;
  width: 75%;
}

.blue-header {
  background-color: #0096d8 !important;
  padding: 1.2rem;
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  border-radius: 100em;
  min-height: 95px !important;
  margin-bottom: 7px;
  font-weight: 600;
  width: 75%;
}

.purple-header span,
.blue-header span {
  display: block;
}

.price {
  font-weight: 500;
  font-size: 1.5rem;
}

.price .small-text {
  font-size: 1rem;
}

.price-admin-fee {
  font-weight: 500;
  font-size: 1rem;
}

.bold-text {
  font-weight: 700;
}

.unit-text {
  background-color: #f2f2f5 !important;
  color: #0096d8 !important;
  font-size: 0.9em;
  text-align: center;
  line-height: 0.5em !important;
  border-radius: 100em;
  padding: 1rem 1.2rem !important;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 7px;
  width: 80%;
}

.package-info-block {
  text-align: left;
  width: 85%;
}

.package-info {
  font-size: 1rem;
}

a.btn-outline-light:focus {
  text-decoration: none !important;
  color: #FFF !important;
}

a.btn-outline-light:hover{
  color: #4272f5 !important;
}

.price-slash {
  font-size: 1rem !important;
}