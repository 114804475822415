.testb1 {
    border: 1px solid red !important;
}

.testb2 {
    border: 1px solid blue !important;
}

.testb3 {
    border: 1px solid green !important;
}

body {
    scroll-behavior: smooth !important;
}

.no-border {
    border: none !important;
}

/* Links in top bar */
.top-bar a {
    color: inherit !important;
}

.top-bar a:hover {
    color: #4272f5 !important;
    text-decoration: none !important;
}

.attribution-link {
    color: #4272f5 !important;
}

.attribution-link:hover {
    color: #5a84fa !important;
    text-decoration: none !important;
}

.form-control {
    border-radius: 15px !important;
}

.form-control:focus {
    border-color: #5a84fa;
}

.btn {
    border-radius: 15px !important;
}

.round-border {
    border-radius: 20px !important;
}

.contact-link {
    color: inherit;
    transition: all 0.3s;
}

.contact-link:hover,
.contact-link:focus {
    color: #4272f5;
    text-decoration: none;
}

.hero-slide::before,
.projects-hero::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
    display: block;
}

/* Image Modal Code */
/* Style the Image Used to Trigger the Modal */
.modal-img {
    border-radius: 5px;
    cursor: pointer;
    /* transition: 0.3s; */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    width: 100%;
}

.modal-img:hover {
    opacity: 0.7;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 9999;
    /* Sit on top */
    padding-top: 70px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    width: 40% !important;
    /* max-width: 700px; */
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
.caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
.caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

/* Set Select to be better */
select.form-control {
    padding: .5rem .5rem !important;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2rem + 4px);
}

.text-xsm {
    font-size: 0.65rem !important;
}

.text-xxsm {
    font-size: 0.25rem !important;
}

.text-xl {
    font-size: 2.5rem;
}

.text-xxl {
    font-size: 3.5rem;
}

.remove-on-hover:hover {
    text-decoration: none !important;
}

.underline-on-hover:hover {
    text-decoration: underline !important;
}

@media screen and (min-width: 376px) and (max-width: 480px) {
    .business-header {
        padding-left: .2rem !important;
    }

    .business-name {
        font-size: 1.2rem !important;
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .business-header {
        padding-left: .1rem !important;
    }

    .business-name {
        font-size: 1rem !important;
    }

    .contact-header li a {
        font-size: 0.8rem !important;
    }

    .contact-header .mid-bar {
        display: none !important;
    }

    .first-hero-btn {
        margin-bottom: 1rem !important;
    }

    .hero-slide h1 {
        font-size: 1.5rem !important;
    }

    p.lead {
        font-size: 1rem !important;
    }

    .center-btn-on-mobile {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
}