/*
=========================================
=========================================

   Consultive - Bootstrap Template

=========================================
========================================= */
/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/
.testimonial {
  position: relative;
  background: url(../images/row-bgimage-3.png) no-repeat;
  background-position: top center;
  background-size: 70%;
}

.testimonial-images {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
}

.testimonial-images::after {
  content: '\f10e';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  background: #4272f5;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8rem;
}

.accordion a {
  background: #f8f9fa;
}

.accordion a[aria-expanded='true'] {
  background: #4272f5;
  color: #fff;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #4272f5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.gmap {
  height: 350px;
}

.btn-outline-light:hover, .btn-outline-light:focus {
  color: #4272f5 !important;
}

/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/
.top-bar [class*="col"].bg-primary {
  position: relative;
}

.top-bar [class*="col"].bg-primary::before {
  content: '';
  display: block;
  position: absolute;
  width: 20rem;
  height: 100%;
  top: 0;
  left: -20rem;
  background: #4272f5;
}

/*
*
* ==========================================
* FOOTER
* ==========================================
*
*/
.footer-link {
  color: #6c757d;
  transition: all 0.3s;
  cursor: pointer;
}

.footer-link:hover, .footer-link:focus {
  color: #4272f5;
  text-decoration: none;
}

/*
* ===================================================
*    Custom Orion Icons
* ===================================================
*/
.svg-icon {
  width: 40px;
  height: 40px;
  vertical-align: text-bottom;
  stroke: currentColor;
  stroke-width: 3;
  --layer1: currentColor;
  --layer2: currentColor;
  --layer3: currentColor;
}

.svg-icon.svg-icon-light {
  stroke-width: 2;
}

.svg-icon.svg-icon-heavy {
  stroke-width: 4;
}

.svg-icon-big {
  width: 64px;
  height: 64px;
}

.svg-icon-sm {
  width: 32px;
  height: 32px;
}

/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/
/*
   Text utils
   ------------------------
*/
.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  letter-spacing: .1em;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.25rem;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
  text-shadow: 2px 2px 2px rgba(43, 144, 217, 0.1);
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-inherit {
  color: inherit;
}

/*
    Rounded corners - lg, sm
   ------------------------
*/
.rounded-lg {
  border-radius: 0.3rem;
}

.rounded-sm {
  border-radius: 0.2rem;
}

.rounded-xl {
  border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
/*
    Opacity helpers - .opacity-[1-9]
    ------------------------
*/
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/*
    Z-index helpers - .z-index-[10-50]
    ------------------------
*/
.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.z-index-30 {
  z-index: 30;
}

.z-index-40 {
  z-index: 40;
}

.z-index-50 {
  z-index: 50;
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/
.letter-spacing-0 {
  letter-spacing: 0 !important;
}

.letter-spacing-1 {
  letter-spacing: 0.1em;
}

.letter-spacing-2 {
  letter-spacing: 0.2em;
}

.letter-spacing-3 {
  letter-spacing: 0.3em;
}

.letter-spacing-4 {
  letter-spacing: 0.4em;
}

.letter-spacing-5 {
  letter-spacing: 0.5em;
}

/*
     Colours and backgrounds
    ------------------------
*/
.text-hover-primary {
  transition: all 0.2s ease-in-out;
}

.text-hover-primary:focus, .text-hover-primary:hover {
  color: #4272f5 !important;
}

a.text-hover-primary {
  transition: all 0.2s ease-in-out;
}

a.text-hover-primary:focus, a.text-hover-primary:hover {
  color: #4272f5 !important;
}

.bg-primary-light {
  background-color: #e6f2fa;
}

.border-primary {
  border-color: #4272f5 !important;
}

.text-hover-secondary {
  transition: all 0.2s ease-in-out;
}

.text-hover-secondary:focus, .text-hover-secondary:hover {
  color: #6c757d !important;
}

a.text-hover-secondary {
  transition: all 0.2s ease-in-out;
}

a.text-hover-secondary:focus, a.text-hover-secondary:hover {
  color: #6c757d !important;
}

.bg-secondary-light {
  background-color: #edeeef;
}

.border-secondary {
  border-color: #6c757d !important;
}

.text-hover-success {
  transition: all 0.2s ease-in-out;
}

.text-hover-success:focus, .text-hover-success:hover {
  color: #28a745 !important;
}

a.text-hover-success {
  transition: all 0.2s ease-in-out;
}

a.text-hover-success:focus, a.text-hover-success:hover {
  color: #28a745 !important;
}

.bg-success-light {
  background-color: #e5f4e9;
}

.border-success {
  border-color: #28a745 !important;
}

.text-hover-info {
  transition: all 0.2s ease-in-out;
}

.text-hover-info:focus, .text-hover-info:hover {
  color: #17a2b8 !important;
}

a.text-hover-info {
  transition: all 0.2s ease-in-out;
}

a.text-hover-info:focus, a.text-hover-info:hover {
  color: #17a2b8 !important;
}

.bg-info-light {
  background-color: #e3f4f6;
}

.border-info {
  border-color: #17a2b8 !important;
}

.text-hover-warning {
  transition: all 0.2s ease-in-out;
}

.text-hover-warning:focus, .text-hover-warning:hover {
  color: #ffc107 !important;
}

a.text-hover-warning {
  transition: all 0.2s ease-in-out;
}

a.text-hover-warning:focus, a.text-hover-warning:hover {
  color: #ffc107 !important;
}

.bg-warning-light {
  background-color: #fff8e1;
}

.border-warning {
  border-color: #ffc107 !important;
}

.text-hover-danger {
  transition: all 0.2s ease-in-out;
}

.text-hover-danger:focus, .text-hover-danger:hover {
  color: #dc3545 !important;
}

a.text-hover-danger {
  transition: all 0.2s ease-in-out;
}

a.text-hover-danger:focus, a.text-hover-danger:hover {
  color: #dc3545 !important;
}

.bg-danger-light {
  background-color: #fbe7e9;
}

.border-danger {
  border-color: #dc3545 !important;
}

.text-hover-light {
  transition: all 0.2s ease-in-out;
}

.text-hover-light:focus, .text-hover-light:hover {
  color: #f8f9fa !important;
}

a.text-hover-light {
  transition: all 0.2s ease-in-out;
}

a.text-hover-light:focus, a.text-hover-light:hover {
  color: #f8f9fa !important;
}

.bg-light-light {
  background-color: #fefefe;
}

.border-light {
  border-color: #f8f9fa !important;
}

.text-hover-dark {
  transition: all 0.2s ease-in-out;
}

.text-hover-dark:focus, .text-hover-dark:hover {
  color: #111 !important;
}

a.text-hover-dark {
  transition: all 0.2s ease-in-out;
}

a.text-hover-dark:focus, a.text-hover-dark:hover {
  color: #111 !important;
}

.bg-dark-light {
  background-color: #e2e2e2;
}

.border-dark {
  border-color: #111 !important;
}

.bg-gray-100 {
  background: #f8f9fa !important;
}

.text-gray-100 {
  color: #f8f9fa;
}

.bg-gray-200 {
  background: #e9ecef !important;
}

.text-gray-200 {
  color: #e9ecef;
}

.bg-gray-300 {
  background: #dee2e6 !important;
}

.text-gray-300 {
  color: #dee2e6;
}

.bg-gray-400 {
  background: #ced4da !important;
}

.text-gray-400 {
  color: #ced4da;
}

.bg-gray-500 {
  background: #adb5bd !important;
}

.text-gray-500 {
  color: #adb5bd;
}

.bg-gray-600 {
  background: #6c757d !important;
}

.text-gray-600 {
  color: #6c757d;
}

.bg-gray-700 {
  background: #495057 !important;
}

.text-gray-700 {
  color: #495057;
}

.bg-gray-800 {
  background: #343a40 !important;
}

.text-gray-800 {
  color: #343a40;
}

.bg-gray-900 {
  background: #111 !important;
}

.text-gray-900 {
  color: #111;
}

.badge-primary-light {
  color: #4272f5;
  background-color: #e6f2fa;
}

.badge-secondary-light {
  color: #6c757d;
  background-color: #edeeef;
}

.badge-success-light {
  color: #28a745;
  background-color: #e5f4e9;
}

.badge-info-light {
  color: #17a2b8;
  background-color: #e3f4f6;
}

.badge-warning-light {
  color: #ffc107;
  background-color: #fff8e1;
}

.badge-danger-light {
  color: #dc3545;
  background-color: #fbe7e9;
}

.badge-light-light {
  color: #f8f9fa;
  background-color: #fefefe;
}

.badge-dark-light {
  color: #111;
  background-color: #e2e2e2;
}

/*
     Transparent overlays
    ------------------------
*/
.overlay-content {
  position: relative;
  z-index: 20;
}

@media (min-width: 576px) {
  .light-overlay-sm,
  .overlay-hover-light-sm {
    position: relative;
  }
  .light-overlay-sm::after,
  .overlay-hover-light-sm::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 576px) {
  .overlay-hover-light-sm::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 576px) {
  .light-overlay-sm::after,
  .overlay-hover-light-sm:hover::after {
    opacity: .4;
  }
}

@media (min-width: 576px) {
  .light-overlay-sm-0::after,
  .overlay-hover-light-sm-0:hover::after {
    display: none;
  }
}

.dark-overlay-sm {
  position: relative;
}

@media (min-width: 576px) {
  .dark-overlay-sm::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .9;
    background: #111;
  }
}

.primary-overlay-sm {
  position: relative;
}

@media (min-width: 576px) {
  .primary-overlay-sm::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .85;
    background: #4272f5;
  }
}

.dark-overlay-sm-lighter {
  position: relative;
}

@media (min-width: 576px) {
  .dark-overlay-sm-lighter::after {
    opacity: .85;
  }
}

@media (min-width: 576px) {
  .dark-overlay-sm-0::after {
    display: none;
  }
}

@media (min-width: 768px) {
  .light-overlay-md,
  .overlay-hover-light-md {
    position: relative;
  }
  .light-overlay-md::after,
  .overlay-hover-light-md::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 768px) {
  .overlay-hover-light-md::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .light-overlay-md::after,
  .overlay-hover-light-md:hover::after {
    opacity: .4;
  }
}

@media (min-width: 768px) {
  .light-overlay-md-0::after,
  .overlay-hover-light-md-0:hover::after {
    display: none;
  }
}

.dark-overlay-md {
  position: relative;
}

@media (min-width: 768px) {
  .dark-overlay-md::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .9;
    background: #111;
  }
}

.primary-overlay-md {
  position: relative;
}

@media (min-width: 768px) {
  .primary-overlay-md::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .85;
    background: #4272f5;
  }
}

.dark-overlay-md-lighter {
  position: relative;
}

@media (min-width: 768px) {
  .dark-overlay-md-lighter::after {
    opacity: .85;
  }
}

@media (min-width: 768px) {
  .dark-overlay-md-0::after {
    display: none;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg,
  .overlay-hover-light-lg {
    position: relative;
  }
  .light-overlay-lg::after,
  .overlay-hover-light-lg::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 992px) {
  .overlay-hover-light-lg::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg::after,
  .overlay-hover-light-lg:hover::after {
    opacity: .4;
  }
}

@media (min-width: 992px) {
  .light-overlay-lg-0::after,
  .overlay-hover-light-lg-0:hover::after {
    display: none;
  }
}

.dark-overlay-lg {
  position: relative;
}

@media (min-width: 992px) {
  .dark-overlay-lg::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .9;
    background: #111;
  }
}

.primary-overlay-lg {
  position: relative;
}

@media (min-width: 992px) {
  .primary-overlay-lg::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .85;
    background: #4272f5;
  }
}

.dark-overlay-lg-lighter {
  position: relative;
}

@media (min-width: 992px) {
  .dark-overlay-lg-lighter::after {
    opacity: .85;
  }
}

@media (min-width: 992px) {
  .dark-overlay-lg-0::after {
    display: none;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl,
  .overlay-hover-light-xl {
    position: relative;
  }
  .light-overlay-xl::after,
  .overlay-hover-light-xl::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: #fff;
  }
}

@media (min-width: 1200px) {
  .overlay-hover-light-xl::after {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl::after,
  .overlay-hover-light-xl:hover::after {
    opacity: .4;
  }
}

@media (min-width: 1200px) {
  .light-overlay-xl-0::after,
  .overlay-hover-light-xl-0:hover::after {
    display: none;
  }
}

.dark-overlay-xl {
  position: relative;
}

@media (min-width: 1200px) {
  .dark-overlay-xl::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .9;
    background: #111;
  }
}

.primary-overlay-xl {
  position: relative;
}

@media (min-width: 1200px) {
  .primary-overlay-xl::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .85;
    background: #4272f5;
  }
}

.dark-overlay-xl-lighter {
  position: relative;
}

@media (min-width: 1200px) {
  .dark-overlay-xl-lighter::after {
    opacity: .85;
  }
}

@media (min-width: 1200px) {
  .dark-overlay-xl-0::after {
    display: none;
  }
}

.light-overlay,
.overlay-hover-light {
  position: relative;
}

.light-overlay::after,
.overlay-hover-light::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  background: #fff;
}

.overlay-hover-light::after {
  transition: opacity 0.15s linear;
  opacity: 0;
}

.light-overlay::after,
.overlay-hover-light:hover::after {
  opacity: .4;
}

.light-overlay-0::after,
.overlay-hover-light-0:hover::after {
  display: none;
}

.dark-overlay {
  position: relative;
}

.dark-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  opacity: .9;
  background: #111;
}

.primary-overlay {
  position: relative;
}

.primary-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  opacity: .85;
  background: #4272f5;
}

.dark-overlay-lighter {
  position: relative;
}

.dark-overlay-lighter::after {
  opacity: .85;
}

.dark-overlay-0::after {
  display: none;
}

/*
     Other
    ------------------------
*/
.overflow-visible {
  overflow: visible !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.text-small {
  font-size: 0.85rem !important;
}

.bg-cover {
  background-size: cover !important;
  background-position: center center !important;
}

.bg-center {
  background-position: 50% 50% !important;
}

@media (min-width: 992px) {
  h1:not(.h2):not(.h3):not(.h4):not(.h5):not(.h6), .h1 {
    font-size: 3.5rem !important;
  }
}

.hero-slide, .hero {
  position: relative;
}

.hero-slide::before, .hero::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  display: block;
}

.hero-slide {
  position: relative;
  min-height: 650px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .hero-slide {
    min-height: auto;
  }
}

.index-forward {
  position: relative;
  z-index: 99;
}

.border-width-md {
  border-width: 2px !important;
}

.reset-anchor {
  color: inherit;
  border: none;
  background: none;
}

.reset-anchor:hover, .reset-anchor:focus {
  color: inherit;
  text-decoration: none;
}

.bg-none {
  background: none !important;
}

.bg-darker {
  background: #0c0c0c !important;
}

.list-check li {
  padding-left: 1.5rem;
  position: relative;
}

.list-check li::before:last-of-type {
  content: '\f058';
  display: block;
  position: absolute;
  top: 25%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #4272f5;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
}

.list-check li::before {
  content: '\f058';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #4272f5;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
}

.border-dash {
  border-style: dashed !important;
}

ol.list-numbers {
  list-style: none;
  padding-left: 0;
  counter-reset: my-awesome-counter;
}

ol.list-numbers li {
  counter-increment: my-awesome-counter;
  position: relative;
  padding-left: 3.5rem;
}

ol.list-numbers li::before {
  content: counter(my-awesome-counter) ". ";
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #4272f5;
}

.video-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  background: #4272f5;
  text-align: center;
  line-height: 60px;
  display: inline-block;
  position: relative;
  z-index: 2;
  border: none;
  outline: none !important;
}

.video-btn::before {
  content: '';
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  background: rgba(43, 144, 217, 0.5);
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: -1;
  transition: all .35s;
}

.video-btn::after {
  content: '';
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: block;
  background: rgba(43, 144, 217, 0.2);
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: -1;
  transition: all .35s;
}

.video-btn:hover, .video-btn:focus {
  color: #fff;
  text-decoration: none;
}

.video-btn:hover::before, .video-btn:hover::after, .video-btn:focus::before, .video-btn:focus::after {
  -webkit-animation: bouncing 2s infinite;
  animation: bouncing 2s infinite;
}

@-webkit-keyframes bouncing {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes bouncing {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

.owl-nav-custom {
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.owl-nav-custom .owl-nav {
  position: absolute;
  top: calc(50% - 1.75rem);
  left: 2.5rem;
  width: calc(100% - 5rem);
  height: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .owl-nav-custom .owl-nav {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .owl-nav-custom .owl-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    top: auto;
    bottom: 1.75rem;
    -webkit-transform: none;
    transform: none;
  }
}

.owl-nav-custom .owl-nav .owl-prev, .owl-nav-custom .owl-nav .owl-next {
  width: 3.5rem;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.5) !important;
  line-height: 3.5rem !important;
  transition: all 0.3s;
  outline: none;
  text-align: center;
  color: #fff;
  position: relative;
}

.owl-nav-custom .owl-nav .owl-prev.disabled, .owl-nav-custom .owl-nav .owl-next.disabled {
  opacity: 0;
  z-index: -1;
}

.owl-nav-custom .owl-nav .owl-prev span, .owl-nav-custom .owl-nav .owl-next span {
  color: #fff;
  font-size: 2.5rem;
}

.owl-nav-custom .owl-nav .owl-prev:hover, .owl-nav-custom .owl-nav .owl-next:hover {
  background: #4272f5 !important;
}

@media (min-width: 992px) {
  .owl-nav-custom .owl-nav .owl-prev::before, .owl-nav-custom .owl-nav .owl-next::before {
    content: 'Prev';
    width: 5rem;
    height: 3.5rem;
    position: absolute;
    top: 0;
    right: -5rem;
    background: rgba(0, 0, 0, 0.8);
    line-height: 3.3rem;
    transition: all 0.3s;
    text-align: center;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
}

.owl-nav-custom .owl-nav .owl-prev:hover::before, .owl-nav-custom .owl-nav .owl-next:hover::before {
  -webkit-transform: none;
  transform: none;
}

@media (min-width: 992px) {
  .owl-nav-custom .owl-nav .owl-next::before {
    content: 'Next';
    right: auto;
    left: -5rem;
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
}

.owl-theme .owl-dots .owl-dot {
  outline: none;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 3px;
  width: 10px;
  height: 10px;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #4272f5;
}

.owl-carousel .owl-item images {
  width: unset;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 1.5rem;
}

.modal-video-close-btn {
  right: 0;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  left: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  left: 0;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================

      THEMING OF THE BOOTSTRAP COMPONENTS

   =========================================

    1 - NAVBAR
    2 - BUTTONS
    3 - TYPE
    4 - PAGINATION
    5 - UTILITIES
    6 - FORMS
    7 - CODE
    8 - NAV
    9 - CARD
    10 - DROPDOWNS

*/
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.navbar-light .navbar-brand {
  color: #4272f5;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #4272f5;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  font-weight: 700;
  font-size: 0.9rem;
  transition: all 0.3s;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #4272f5;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #4272f5;
}

.navbar-light .navbar-toggler {
  color: black;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: black;
}

.navbar-dark .navbar-brand {
  color: #4272f5;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #4272f5;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #4272f5;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #4272f5;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 700;
  border: 2px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.btn-link {
  font-weight: 400;
  color: #4272f5;
}

.btn-link:hover {
  color: #1c669c;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-primary {
  color: #fff;
  background-color: #4272f5;
  border-color: #4272f5;
}

.btn-primary:hover {
  color: #fff;
  background-color: #5d85f3;
  border-color: #5d85f3;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4272f5;
  border-color: #4272f5;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5d85f3;
  border-color: #1e6da6;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #111;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #111;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #111;
  border-color: #111;
}

.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #111;
  border-color: #111;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-outline-primary {
  color: #4272f5;
  background-color: transparent;
  background-image: none;
  border-color: #4272f5;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4272f5;
  border-color: #4272f5;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4272f5;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4272f5;
  border-color: #4272f5;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #111;
  background-color: transparent;
  background-image: none;
  border-color: #111;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #111;
  border-color: #111;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #111;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #111;
  border-color: #111;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-lg {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.btn-sm {
  padding: 0.45rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0rem;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111;
  background-color: #fff;
}

a {
  color: #4272f5;
  text-decoration: none;
}

a:hover, a:focus {
  color: #1c669c;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border-left: 5px solid #4272f5;
}

.blockquote-footer {
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #4272f5 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #5d85f3 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #4272f5;
  border-color: #4272f5;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.breadcrumb-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.breadcrumb-item a {
  color: #fff !important;
  transition: all 0.3s;
}

.breadcrumb-item a:hover {
  color: #4272f5 !important;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #4272f5;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '|';
  color: white;
  padding-right: 1rem;
  font-weight: 400;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #4272f5;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #1c669c;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.25);
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. UTILITIES
*/
.bg-primary {
  /* background-color: #2b90d9 !important; */
  background-color: #4272f5 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  /* background-color: #2074b1 !important; */
  background-color: #5d85f3 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #111 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.border-primary {
  border-color: #4272f5 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #111 !important;
}

.text-primary {
  color: #4272f5 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #5d85f3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #111 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.badge-primary {
  color: #fff;
  background-color: #4272f5;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #5d85f3;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #111;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #111;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #111;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #111;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #111;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/*
  * 6. FORMS
  */
.form-control {
  padding: 1.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #97c9ec;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 144, 217, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #a1a8ae;
}

.form-control::-moz-placeholder {
  color: #a1a8ae;
}

.form-control:-ms-input-placeholder {
  color: #a1a8ae;
}

.form-control::-ms-input-placeholder {
  color: #a1a8ae;
}

.form-control::placeholder {
  color: #a1a8ae;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(4.5rem + 4px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  padding: 0.9rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(3.1125rem + 4px);
}

.form-control-lg {
  padding: 2rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(5.875rem + 4px);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #4272f5;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(43, 144, 217, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #c2dff4;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4272f5;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4272f5;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 144, 217, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(43, 144, 217, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4272f5;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 144, 217, 0.5);
}

/*
* 7.CODE
*/
code {
  font-size: 87.5%;
  color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4272f5;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-images-overlay {
  padding: 1.25rem;
}

.card-images-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-images {
  border-radius: calc(0.25rem - 1px);
}

.card-images-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-images-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

/*
* 10. DROPDOWNS
*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #111;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
  border: none;
}

@media (min-width: 992px) {
  .dropdown-menu {
    border-top: 2px solid #4272f5;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
  font-weight: 400;
  color: #111;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #040404;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #4272f5;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}
